import { TJobRecord, TJobSector, TOffersSuggested } from "#typesjobs";
import { TItems, TMiscOptions, TProductClick } from "#typestagManager";
import { tableRowsToIndexPerPage } from "@utilscommon/tableRowsToIndex";
import { itemListId } from "@utils/strings";
import { Video } from "#typeslanding";
import { itemCategorySchema } from "@schemasgoogleAnalytics/itemCategory.schema";
import { EEventsGA4 } from "@enumsgoogleAnalyticsV4.enum";

export const jobOfferToImpressions = (jobOffersData: TJobRecord[]) => {
  const impressions: TProductClick[] = jobOffersData?.map((offer, index) => ({
    id: offer.id,
    name: offer.title,
    brand: offer.companyName,
    category: offer.cities?.join(","),
    price: offer.minSalary,
    variant: offer.description,
    list: offer.companyName,
    position: index + 1
  }));
  return impressions;
};

const normalizeItems = {
  itemId: "item_id",
  itemName: "item_name",
  itemListName: "item_list_name",
  itemListId: "item_list_id",
  itemBrand: "item_brand",
  itemVariant: "item_variant",
  transactionId: "transaction_id",
  paymentType: "payment_type"
};

export const jobOfferToProductTag = (jobOffer: TJobRecord, index?: number) => {
  const productTag: TProductClick = {
    id: jobOffer.id,
    name: jobOffer.title,
    brand: jobOffer.companyName,
    category: jobOffer.cities?.join(","),
    price: jobOffer.minSalary,
    variant: jobOffer.sectors?.map((sector) => sector.name)?.join(","),
    list: jobOffer.companyName,
    position: index ? index + 1 : 1
  };
  return productTag;
};

export const jsonDataLayer = (
  jobOffersData: TJobRecord[] | TOffersSuggested[] | Video[],
  listName: string,
  section: string,
  event: string,
  miscOptions?: TMiscOptions
) => {
  const sectorsToCategory = (sectors: TJobSector[]) => {
    return itemCategorySchema.map((item, index) => {
      return {
        [item]: `${sectors && sectors[index] ? sectors[index].name : ""}`
      };
    });
  };

  const items: TItems[] = jobOffersData?.map((offer, index) => {
    return {
      [normalizeItems.itemId]: offer.publicId,
      [normalizeItems.itemName]: offer.title,
      [normalizeItems.itemListName]: listName,
      [normalizeItems.itemListId]: itemListId(listName),
      index:
        event === EEventsGA4.addToCart ? miscOptions?.indexOrigin : tableRowsToIndexPerPage(index, miscOptions?.page),
      [normalizeItems.itemBrand]: offer.companyName?.name || offer.companyName,
      [normalizeItems.itemVariant]: offer.cities?.length && offer.cities[0],
      price: offer.toAgree ? 0 : offer.minSalary,
      currency: "COP",
      quantity: offer.numberOfQuotas,
      ...Object.assign({}, ...sectorsToCategory(offer.sectors))
    };
  });

  return {
    event: event,
    ...((event === EEventsGA4.addToCart || event === EEventsGA4.purchase) && {
      currency: "COP",
      value: items[0].price,
      position: miscOptions?.position
    }),
    ...(event === EEventsGA4.purchase && {
      [normalizeItems.transactionId]: miscOptions?.applicationId,
      coupon: "",
      [normalizeItems.paymentType]: ""
    }),
    ...(event === EEventsGA4.viewItemList && {
      [normalizeItems.itemListName]: listName,
      [normalizeItems.itemListId]: itemListId(listName)
    }),
    ...(event !== EEventsGA4.purchase && { section: section }),
    ecommerce: { items: [...items] }
  };
};

export const jsonAuthDataLayer = (userId: number) => {
  if (!userId) return;
  return {
    event: "authentication",
    userId: userId
  };
};
